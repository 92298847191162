import { VuexModule, Module, Mutation, Action } from "vuex-class-modules";
import { UserInterface, responseInterface, CookiesInterface } from "@/types";
import api from "@/apiClient";
import { setCookie, getCookie, compareObject, encrypt, decrypt } from "@/utils";

const VUE_APP_USER_KEY = process.env.VUE_APP_USER_KEY || "";
const VUE_APP_TOKEN_KEY = process.env.VUE_APP_TOKEN_KEY || "";

const retrieveUserData = (): UserInterface | null => {
  if (!getCookie(VUE_APP_USER_KEY)) return null;

  const userData = getCookie(VUE_APP_USER_KEY)?.split("|");
  const userToken = userData!.length > 1 ? userData![1] : "";

  try {
    return JSON.parse(decrypt(userToken, "AES"));
  } catch (error) {
    return null;
  }
};

const storeUserData = (user: UserInterface): void => {
  if (!user) return;

  setCookie(
    VUE_APP_USER_KEY!,
    `${user.username}|${encrypt(JSON.stringify(user), "AES")}`,
    1
  );
};

const resetCookies = (cookies: CookiesInterface | null = null): void => {
  if (cookies) {
    Object.keys(cookies).forEach((key) => setCookie(key!, "", -1));
  }

  const cookieNames = [VUE_APP_TOKEN_KEY, VUE_APP_USER_KEY];

  cookieNames.forEach((name) => setCookie(name!, "", -1));
};

@Module
export default class UsersModule extends VuexModule {
  private user: UserInterface | null = retrieveUserData();
  private userToken = getCookie(VUE_APP_TOKEN_KEY);
  private loader = false;
  private errorMessage = "";
  private responseErrorMessage = "";
  private successMessage = "";

  @Action
  public async CheckUserAccess(): Promise<void> {
    try {
      this.setLoading(true);

      const response: responseInterface = await api.user.checkAccess();
      if (response.statusCode !== 200) return;

      const user: UserInterface = response.data;
      // const storedUser = retrieveUserData();

      // if (!compareObject(user, storedUser)) {
      //   this.setUser(user);
      //   storeUserData(user);
      // }
      this.setUser(user);
    } catch (e) {
      console.error(e);
      this.ResetUser();
      this.setErrorMessage("Access not allowed..");
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  public async WpUserAuthenticationToken(): Promise<void> {
    try {
      this.setLoading(true);

      await api.user.wpAuthToken();
      await this.CheckUserAccess();
    } catch (e) {
      this.ResetUser();
      this.setErrorMessage("Authentication token is not valid...");
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  public ResetUser(cookies: any = null): void {
    resetCookies(cookies);
    this.setToken("");
    this.setUser(retrieveUserData() as UserInterface);
    this.ResetMessages();
  }

  @Action
  public ResetMessages(): void {
    this.setErrorMessage("");
    this.setResponseErrorMessage("");
    this.setSuccessMessage("");
  }

  @Mutation
  private setUser(user: UserInterface) {
    this.user = user;
  }

  @Mutation
  private setToken(token: string) {
    this.userToken = token;
  }

  @Mutation
  private setLoading(loader: boolean) {
    this.loader = loader;
  }

  @Mutation
  public setErrorMessage(message: string) {
    this.errorMessage = message;
  }

  @Mutation
  public setResponseErrorMessage(message: string) {
    this.responseErrorMessage = message;
  }

  @Mutation
  public setSuccessMessage(message: string) {
    this.successMessage = message;
  }

  get userData(): Partial<UserInterface> {
    return this.user || { access: 0 };
  }

  get IsAuthenticated(): boolean {
    return !!this.userToken;
  }

  get isLoading(): boolean {
    return this.loader;
  }

  get storedUser(): UserInterface | null {
    return retrieveUserData();
  }
}
