
import { Options, Vue } from "vue-class-component";
import {
  settingsModule,
  assetsModule,
  categoriesModule,
  usersModule,
} from "@/store";

import PageLayout from "@/components/layouts/index.vue";

@Options({
  name: "app",
  components: {
    PageLayout,
  },
})
export default class App extends Vue {
  protected get isAuthenticated(): boolean {
    return usersModule.IsAuthenticated;
  }

  created(): void {
    if (this.isAuthenticated) {
      usersModule.WpUserAuthenticationToken();
      assetsModule.LoadPinnedAssets();
    }

    settingsModule.Load();
    categoriesModule.Load();
    assetsModule.LoadAllData();
  }
}
