
import { Options, Vue } from "vue-class-component";
import config from "@/config";
import { assetsModule, usersModule } from "@/store";
import { CredentialsInterface } from "@/types";

@Options({
  props: {
    isAuth: {
      type: Boolean,
      default: false,
    },
  },
})
export default class BaseButtonAccountDropdown extends Vue {
  protected fullPath(path: string): string {
    return config.base + path;
  }

  protected getSignInLinkPath(path: string): string {
    return this.fullPath(path + "?redirect_to=" + process.env.VUE_APP_DOMAIN)
  }

  protected getLogOutLinkPath(path: string): string {
    return this.fullPath(path + "&redirect_to=" + process.env.VUE_APP_DOMAIN)
  }

  protected async handleLinkClick(): Promise<void> {
    assetsModule.ClearFilters();

    this.emitter.emit("drawer-open", false);
    this.emitter.emit("drawer-tags-open", false);
  }
}
