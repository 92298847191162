import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-0037f354")
const _hoisted_1 = { class: "dropdown-wrapper" }
const _hoisted_2 = { class: "inner" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["href"]
const _hoisted_6 = ["href"]
const _hoisted_7 = ["href"]
const _hoisted_8 = ["href"]
const _hoisted_9 = ["href"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isAuth)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
            _createElementVNode("li", null, [
              _createElementVNode("a", {
                href: _ctx.fullPath('my-account')
              }, "My account", 8, _hoisted_4)
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("a", {
                href: _ctx.fullPath('my-account/orders')
              }, "My orders", 8, _hoisted_5)
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("a", {
                href: _ctx.fullPath('frequently-asked-questions')
              }, "FAQ", 8, _hoisted_6)
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("a", {
                href: _ctx.getLogOutLinkPath(`sign-in/?action=logout`)
              }, "Log out", 8, _hoisted_7)
            ])
          ]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("a", {
              class: "v1-button",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleLinkClick && _ctx.handleLinkClick(...args))),
              href: _ctx.fullPath(`sign-in/?action=register`)
            }, "Create a account", 8, _hoisted_8),
            _createElementVNode("a", {
              class: "v1-button",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleLinkClick && _ctx.handleLinkClick(...args))),
              href: _ctx.getSignInLinkPath(`sign-in/`)
            }, "Sign in", 8, _hoisted_9)
          ], 64))
    ])
  ]))
}