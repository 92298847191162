import CryptoJS from "crypto-js";

export const AES = () => {
  return {
    type: "AES",
    encrypt: (data: any) => {
      return CryptoJS.AES.encrypt(
        data,
        process.env.VUE_APP_SECRET_KEY
      ).toString();
    },
    decrypt: (data: any) => {
      return CryptoJS.AES.decrypt(
        data,
        process.env.VUE_APP_SECRET_KEY
      ).toString(CryptoJS.enc.Utf8);
    },
  };
};
